<template>
<!-- eslint-disable -->
      <v-col
          id="leftCol"
          cols="12"
          md="6"
        >
         
        
  <section class="spacing-playground pa-8 teal darken-1">

    <div class=" pb-4 text-center text-h3 white--text ">Dixon</div>
 
    <v-container>
        <v-carousel>
          <v-carousel-item
            v-for="(item,i) in Rosalie"
            :key="i"
            :src="item.pathLong"
            reverse-transition="fade"
            transition="fade"
          ></v-carousel-item>
        </v-carousel>
  </v-container>   
  </section>
  </v-col>
</template>

<script>
  export default {
    data () {
      return {
        Rosalie: [],
      }
    },
    mounted () {
      this.importAll(require.context('@/assets/Rosalie/', true, /\.jpg$/))
    },
    methods: {
      importAll (r) {
        r.keys().forEach(key => (this.Rosalie.push({ pathLong: r(key), pathShort: key })))
      },
    },
  }
</script>
